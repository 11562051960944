<template>
  <section class="profile--wrapper">
    <section-loader :show="loading" />
    <div class="title--text">
      {{ $t('home.sidebarMenu.profile') }}
    </div>
    <change-avatar />
    <form @submit.prevent="updateProfile">
      <div class="input--wrapper">
        <div class="form--group" :class="{ 'has-error': validation.hasError('name') }">
          <div class="left--col">
            <label for="name">
              <span>{{ $t('profile.name') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input id="name" v-model="name" placeholder="Name" class="basic--input" />
            <span class="val-error">{{ validation.firstError('name') }}</span>
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="email">
              <span>{{ $t('loginRegister.email') }}</span>
            </label>
          </div>
          <div class="right--col">
            <input readonly id="email" v-model="email" placeholder="Nama" class="basic--input" />
          </div>
        </div>
        <div class="form--group">
          <div class="left--col">
            <label for="phone">
              <span>{{ $t('profile.phone') }}</span>
            </label>
          </div>
          <div class="right--col" style="position: relative">
            <client-only>
              <vue-tel-input
                id="phone"
                :value="phone ? phone : ''"
                :validCharactersOnly="true"
                defaultCountry="id"
                :onlyCountries="['id']"
                :inputOptions="inputOptions"
              />
            </client-only>
            <div
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
              "
              @click="showVerifyPhone()"
            ></div>
          </div>
        </div>
      </div>

      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('profile.saveBtn') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import ChangeAvatar from '@/components/profile/change-avatar.vue';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  components: {
    VueTelInput,
    ChangeAvatar,
    SectionLoader,
  },
  mixins: [HelperMixin],
  data() {
    return {
      isAgent: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.global.user,
    }),
    inputOptions() {
      return {
        placeholder: this.$t('profile.placeholder.phone'),
      };
    },
    name: {
      get() {
        let user = this.$store.state.global.user;
        return user ? user.name : '';
      },
      set(val) {
        let name = this.cleanInput(val);
        this.$store.commit('global/set_user_input', { name });
      },
    },
    email: {
      get() {
        let user = this.$store.state.global.user;
        return user ? user.email : '';
      },
      set(val) {
        let email = this.cleanInput(val);
        this.$store.commit('global/set_user_input', { email });
      },
    },
    phone: {
      get() {
        let user = this.$store.state.global.user;
        return user ? user.phone_number : '';
      },
      set(phone) {
        this.$store.commit('global/set_user_input', { phone });
      },
    },
  },
  validators: {
    name: {
      validator(value) {
        return Validator.value(value).required(this.$i18n.t('errors.name.required'));
      },
    },
  },
  async mounted() {
    this.isAgent = this.agentRegNo !== null && this.agentRegNo !== undefined;
  },
  methods: {
    showVerifyPhone() {
      this.$modal.show('modal-phone', {
        from: 'profile',
        dismissable: true,
      });
    },
    async updateProfile() {
      const isValid = await this.$validate();
      if (isValid) {
        try {
          this.loading = true;
          let requestData = {
            name: this.name,
            about_me: this.about_me,
          };
          if (this.isAgent) {
            requestData.agent_reg_no = this.agentRegNo;
            requestData.job_title = this.jobTitle;
          }
          let data = await this.$store.dispatch('v2/profile/updateProfile', requestData);
          if (data) {
            this.$swal('Success', this.$t('profile.updateSuccess'), 'success');
            this.$store.dispatch('global/fetch');
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.loading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>
